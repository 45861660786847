@import "@/styles/base/_colors";

.contactUsStyleWrapper {
  :global {
    .contact-us-main {
      max-width: 780px;
      margin: 120px auto 120px;
      color: $color-slate;
      .textarea__wrapper {
        position: relative;
        padding: 0 15px 15px 15px;
        border: 1px solid $color-slate;
        border-radius: 4px;
        textarea {
          border: none !important;
        }
        textarea:not(:placeholder-shown) + label,
        textarea:focus + label {
          background: $color-white;
          min-width: 92%;
        }
        label {
          background: $color-white;
          min-width: auto;
        }
      }
      .question {
        font-size: 32px;
        line-height: 40px;
        font-family: "Helvetica Now Text W05 Light";
        color: $color-slate;
        letter-spacing: -1.6px;
      }
      .description {
        font-size: 16px;
        line-height: 24px;
        font-family: "Helvetica Now Text W05 Regular";
        letter-spacing: -0.55px;
        margin: 20px 0;
        color: $color-dark-gray;
      }
      .required-fields {
        font-size: 14px;
        line-height: 22px;
        font-family: "Helvetica Now Text W05 Regular";
        letter-spacing: -0.45px;
        color: $color-dark-gray;
      }
      .policies {
        text-decoration: underline;
        font-family: "Helvetica Now Text W05 Regular";
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        margin: 20px 0;
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }
      .tab-list {
        margin: 20px 0px 30px 0px;
        border-bottom: 1px solid $color-light-gray;
        display: flex;
        align-items: center;
        justify-content: center;
        .tab-list-item {
          font-size: 20px;
          letter-spacing: -0.4px;
          line-height: 28px;
          padding: 20px 30px 15px 30px;
          margin: 0;
          font-family: "Helvetica Now Text W05 Medium";
        }
        .tab-list-active {
          color: $color-charcoal;
          border-bottom: 4px solid $color-charcoal;
        }
      }
      .optional-form {
        border-top: 1px solid $color-dark-gray;
        border-bottom: 1px solid $color-dark-gray;
        padding: 60px 0px 0px 0px;

        .upload-notes {
          color: $color-dark-gray;
          letter-spacing: -0.55px;
          span:last-child {
            font-family: "Helvetica Now Text W05 Medium";
          }
        }

        .upload-image-container {
          background-color: $color-off-white;
          padding: 0 30px 30px;
          margin: 40px 0 60px;
          &.file-5 {
            padding: 30px;
          }
          .contact-us-image-preview {
            height: 120px;
            display: flex;
            position: relative;
            margin-top: 15px;

            &:first-child {
              margin-top: 0;
            }

            .preview-wrapper {
              width: 120px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $color-extra-light-gray;
              .image-box {
                height: 100%;
                width: 120px;
                cursor: pointer;
              }
            }

            .image-delete img {
              position: absolute;
              right: 10px;
              top: 20px;
              cursor: pointer;
            }

            textarea {
              flex: 1;
              border: none;
              resize: none;
              padding: 15px 30px 15px 20px;
              font-family: "Helvetica Now Text W05 Regular";
            }
            textarea:focus {
              outline: none !important;
            }
            &.active-comment {
              border: 1px solid $color-dark-gray;
              padding: 4px;
              border-radius: 5px;
            }
          }
          .text-count {
            position: absolute;
            right: 10px;
            top: 75%;
          }

          .drag-and-drop-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            padding: 50px 0px 20px;
            color: $color-slate;

            .drag-and-drop-text-large {
              font-size: 32px;
              line-height: 40px;
              letter-spacing: -1.6px;
              font-family: "Helvetica Now Text W05 Light";
            }
            .drag-and-drop-text-small {
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.55px;
              margin-top: 15px;
              margin-bottom: 25px;
            }
            .drag-and-drop-upload-btn {
              .button {
                font-size: 14px;
                color: $color-slate;
                height: auto;
                padding: 10px 30px;
                letter-spacing: -0.25px;
              }
            }
          }
          &__error-msg {
            color: $color-error-red;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.55px;
            text-align: center;
          }
        }
        .radio-wrapper {
          margin: 40px 0;
          font-size: 16px;
          .radio {
            margin-right: 40px;
            span {
              color: $color-dark-gray;
            }
            .radio_custom {
              width: 20px;
              height: 20px;
            }
          }
        }
        .form-wrapper {
          margin: 40px 0 60px 0;
          .modal-link {
            display: block;
            margin-bottom: 20px;
            text-decoration: underline;
            cursor: pointer;
            font-family: "Helvetica Now Text W05 Regular";
            letter-spacing: -0.45px;
            line-height: 22px;
            color: $color-dark-gray;
            font-size: 14px;
          }
          &.textarea__wrapper {
            textarea {
              &::placeholder {
                opacity: 0;
              }
            }
          }
        }
      }
      .contact-us-footer {
        font-size: 14px;
        letter-spacing: -0.45px;
        line-height: 22px;

        &__error-msg {
          color: $color-error-red;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.55px;
          padding-bottom: 10px;
        }
        .contact-us__policies {
          font-family: "Helvetica Now Text W05 Regular";
          letter-spacing: -0.45px;
          line-height: 24px;
          color: $color-dark-gray;
          a {
            margin-right: 20px;
            text-decoration: underline;
            font-family: "Helvetica Now Text W05 Regular";
            letter-spacing: -0.45px;
            color: $color-dark-gray;
            &:hover {
              color: $color-black;
            }
          }
        }
      }
      .radio__custom {
        border: 1px solid $color-dark-gray;
        &::after {
          background: $color-kohler-blue;
        }
      }

      input[type="date"] {
        position: relative;
      }

      input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }

      .select-dropdown__error-message {
        margin: 10px 0;
      }
      .input-field__prefix {
        img {
          width: 21px;
          display: block;
        }
      }
      #contact-user-phone-wrapper {
        img {
          width: 21px;
          height: 21px;
          display: block;
        }
      }
      #contact-installed-installDate-wrapper {
        img {
          width: 19px;
          display: block;
        }
      }
    }

    .custom-close-icone {
      width: 10px;
      height: 10px;
    }

    .contact-us__success-message-wrapper {
      background-color: $color-off-white;
    }
    .contact-us__success-message {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding-top: 130px !important;
      padding-bottom: 150px !important;

      .back {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.65px;
        position: absolute;
        top: 40px;
        background: transparent;
        border: none;

        img {
          margin-right: 20px;
        }
      }
      .message-content {
        padding-right: 20px;
        .title {
          font-size: 60px;
          line-height: 68px;
          letter-spacing: -3.1px;
          font-family: "Helvetica Now Text W05 Light";
        }
        .details {
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.65px;
          font-family: "Helvetica Now Text W05 Regular";
          margin-top: 25px;
        }
      }
    }

    .contact-us-header {
      background-color: $color-off-white;
      &__breadcrumb {
        padding-top: 30px;
        margin-bottom: 40px;
        section {
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.3px;
          color: $color-slate;
        }
      }
      &__breadcrumb-link {
        cursor: pointer;
        color: $color-dark-gray;
        text-decoration: none;
        &:hover {
          color: $color-slate;
        }
      }
      .kf-page-title {
        margin: 40px 0px;
        padding-bottom: 40px;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .contact-us-main {
        max-width: 512px;
        margin: 40px auto 60px auto;

        .mobile-pd-0 {
          padding: 0 !important;
        }
        .mobile-pl-0 {
          padding-left: 0 !important;
        }
        .mobile-pr-0 {
          padding-right: 0 !important;
        }

        .mobile-pl-10 {
          padding-left: 10px !important;
        }
        .mobile-pr-10 {
          padding-right: 10px !important;
        }

        .optional-form {
          padding: 40px 0 0;
          .upload-image-container {
            padding: 30px;
            margin: 40px 0;
            .drag-and-drop-container {
              margin-top: 20px;
              padding: 0;
              .drag-and-drop-text-large,
              .drag-and-drop-text-small {
                display: none;
              }
            }
          }
        }
        .contact-us-footer {
          margin-top: 0;
        }
      }
    }
    @media screen and (max-width: 992px) {
      .contact-us__success-message {
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
        padding-top: 80px !important;
        padding-bottom: 60px !important;
        min-height: auto;
        .back {
          display: none;
        }
        .message-content {
          padding-right: 0px;
          padding-bottom: 40px;
          .title {
            font-size: 40px;
            line-height: 46px;
            letter-spacing: -2.5px;
          }
          .details {
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.65px;
            margin-top: 15px;
          }
        }
      }
      .contact-us-count {
        top: 5px !important;
        padding-right: 15px !important;
      }
      .textarea__wrapper {
        position: relative;
        padding: 0 0 42px 15px !important;
        border: 1px solid $color-slate;
        border-radius: 4px;
        min-height: 278px;
        height: 278px;
        textarea {
          border: none !important;
        }
        textarea:not(:placeholder-shown) + label,
        textarea:focus + label {
          background: $color-white;
          min-width: -webkit-fill-available;
        }
        label {
          background: $color-white;
          min-width: auto;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .contact-us-main {
        max-width: 512px;
        margin: 40px auto 60px;
        .tab-list .tab-list-item {
          padding: 20px 30px 15px 30px;
        }
        .mobile-pd-0 {
          padding: 0 !important;
        }
        .mobile-pl-0 {
          padding-left: 0 !important;
        }
        .mobile-pr-0 {
          padding-right: 0 !important;
        }

        .optional-form {
          padding: 40px 0 0;
          .upload-image-container {
            padding: 30px;
            margin: 40px 0;
            .drag-and-drop-container {
              margin-top: 20px;
              padding: 0;
              .drag-and-drop-text-large,
              .drag-and-drop-text-small {
                display: none;
              }
            }
          }
        }
        .contact-us-footer {
          margin-top: 0;
        }
      }
    }

    @media screen and (max-width: 896px) and (orientation: landscape) {
      .contact-us-main {
        .tab-list .tab-list-item {
          padding: 20px 20px 15px 20px;
        }
      }
    }

    @media (max-width: 480px) {
      .contact-us-main {
        .title {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
        .question {
          font-size: 26px;
          line-height: 34px;
          letter-spacing: -1.5px;
        }
        .description {
          padding-top: 15px;
        }
        .required-fields {
          margin-top: 15px;
        }
      }
      .contact-us-main .tab-list .tab-list-item {
        padding: 20px 20px 15px 20px;
      }
      .contact-us__success-message {
        .button {
          width: 100%;
        }
      }
    }

    @media screen and (max-device-width: 1023px) {
      .contact-us__success-message {
        min-height: 323px;
      }
    }

    .textarea-message {
      resize: none !important;
      padding: 26px 15px 0 0 !important;
    }
    .contact-us-count {
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 14px;
      letter-spacing: -0.45px;
      color: $color-dark-gray;
      top: -7px;
      position: relative;
      float: right;
    }
    textarea:focus {
      outline: none !important;
    }
    .cmp-tabs {
      @media screen and (min-device-width: 767px) {
        .new {
          padding-top: 80px;
        }
      }
    }
    .cmp-tabs {
      @media screen and (max-device-width: 766px) {
        .new {
          padding-top: 80px;
        }
      }
    }
    .cmp-tabs {
      @media screen and (min-device-width: 767px) and (orientation: landscape) {
        .new {
          padding-top: 80px;
        }
      }
    }
    @media screen and (max-device-width: 991px) and (orientation: landscape) {
      .kf-react-container {
        .contact-us__success-message {
          position: relative;
          display: block;
          align-items: center;
          justify-content: space-between;
        }
        .back {
          display: none;
        }
        .kf-react-button.large {
          padding: 0 100px;
        }
      }
      .contact-us-count {
        top: -14px !important;
        padding-right: 15px !important;
      }
      .textarea__wrapper {
        position: relative;
        padding: 0 0 16px 15px !important;
        border: 1px solid $color-slate;
        border-radius: 4px;
        min-height: 200px !important;
        textarea {
          width: 100%;
          height: 200px;
          padding: 15px;
          border-radius: 4px;
          border: none !important;
          background-color: $color-white;
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 18px;
          line-height: 1.44;
          letter-spacing: -0.7px;
          color: $color-slate;
          &:focus {
            border: 1px solid $color-slate;
          }
          &::placeholder {
            opacity: 0;
          }
        }
        textarea:focus {
          border: 1px solid $color-slate;
        }
        textarea:placeholder-shown + label {
          cursor: text;
          max-width: 66.66%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transform-origin: left bottom;
          transform: translate(0, 88%) scale(1.5);
        }
        textarea:not(:placeholder-shown) + label,
        textarea:focus + label {
          transform: translate(0, 0) scale(1);
          background: $color-white;
          min-width: -webkit-fill-available !important;
        }
        label {
          position: absolute;
          left: 15px !important;
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 12px;
          line-height: 1.67;
          letter-spacing: -0.35px;
          color: $color-dark-gray;
          margin-bottom: 0;
          transition: all 0.2s;
          min-width: auto;
          background: $color-white;
        }
      }
      .textare-label-message {
        min-width: auto;
      }
      .textarea__wrapper {
        position: relative;
        padding: 0 15px 15px 15px;
        border: 1px solid $color-slate;
        border-radius: 4px;
        textarea {
          width: 100%;
          height: 200px;
          padding: 15px;
          border-radius: 4px;
          border: none !important;
          background-color: $color-white;
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 18px;
          line-height: 1.44;
          letter-spacing: -0.7px;
          color: $color-slate;
          &:focus {
            border: 1px solid $color-slate;
          }
          &::placeholder {
            opacity: 0;
          }
        }
        textarea:focus {
          border: 1px solid $color-slate;
        }
        textarea:placeholder-shown + label {
          cursor: text;
          max-width: 66.66%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transform-origin: left bottom;
          transform: translate(0, 88%) scale(1.5);
        }
        textarea:not(:placeholder-shown) + label,
        textarea:focus + label {
          transform: translate(0, 0) scale(1);
        }
        label {
          position: absolute;
          left: 15px !important;
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 12px;
          line-height: 1.67;
          letter-spacing: -0.35px;
          color: $color-dark-gray;
          margin-bottom: 0;
          transition: all 0.2s;
        }
      }
    }
    @media screen and (min-width: 1024px) {
      .contact-us__continue-shopping-btn {
        &.kf-react-button {
          padding: 0 60px;
        }
      }
    }

    .kf-react-datepicker {
      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker-popper {
        padding-top: 0;
        margin-top: -15px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        z-index: 999;
      }

      .react-datepicker__header {
        background: #fff;
        border-bottom: none;
        padding: 25px 0 0 0;
      }

      .react-datepicker__navigation {
        top: 25px;
      }

      .react-datepicker__navigation--previous {
        right: 60px;
        left: inherit;
      }

      .react-datepicker__navigation--next {
        right: 25px;
      }

      .react-datepicker__month {
        margin: 20px 25px 25px 25px;
      }

      .react-datepicker-time__header,
      .react-datepicker-year-header,
      .react-datepicker__current-month {
        margin-top: 0;
        color: $color-dark-gray;
        font-weight: normal;
        font-size: 20px;
        font-family: "Helvetica Now Text W05 Regular";
        text-align: left;
        margin-left: 25px;
      }

      .react-datepicker__current-month {
        margin-bottom: 26px;
      }

      .react-datepicker__month-read-view--down-arrow,
      .react-datepicker__month-year-read-view--down-arrow,
      .react-datepicker__navigation-icon:before,
      .react-datepicker__year-read-view--down-arrow {
        border-color: $color-dark-gray;
        border-style: solid;
        border-width: 1px 1px 0 0;
        content: "";
        display: block;
        height: 10px;
        position: absolute;
        top: 11px;
        width: 10px;
      }

      .react-datepicker {
        background-color: #fff;
        color: $color-dark-gray;
        border: 1px solid #fff;
        border-radius: 5px;
        display: inline-block;
        position: relative;
        font-family: "Helvetica Now Text W05 Regular";
      }

      .react-datepicker__day,
      .react-datepicker__day-name,
      .react-datepicker__time-name {
        color: $color-dark-gray;
        display: inline-block;
        width: 32px;
        height: 32px;
        text-align: center;
        margin: 3px;
        font-family: "Helvetica Now Text W05 Regular";
        font-size: 14px;
        line-height: 32px;
        letter-spacing: -0.65px;
      }

      .react-datepicker__day--selected,
      .react-datepicker__day:hover {
        border-radius: 0;
        background-color: $color-slate;
        color: #fff;
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day--outside-month {
        background: none;
        color: #757575;
      }

      button.react-datepicker__close-icon {
        height: 60px;
        width: 48px;
      }

      .react-datepicker__close-icon::after {
        background: none;
        color: $color-dark-gray;
        font-size: 24px;
        margin: auto;
        text-align: center;
        width: 32px;
        left: 0;
        right: 0;
        border-radius: 0;
        display: block;
        height: 32px;
        font-family: "Helvetica Now Text W05 Light";
      }

      .react-datepicker__month-text--today,
      .react-datepicker__quarter-text--today,
      .react-datepicker__year-text--today {
        border-radius: 0;
        background-color: $color-slate;
        color: #fff;
      }

      .react-datepicker__day--disabled,
      .react-datepicker__month-text--disabled,
      .react-datepicker__quarter-text--disabled,
      .react-datepicker__year-text--disabled,
      .react-datepicker__day--disabled:hover,
      .react-datepicker__month-text--disabled:hover,
      .react-datepicker__quarter-text--disabled:hover,
      .react-datepicker__year-text--disabled:hover {
        cursor: default;
        background-color: transparent;
        color: #ccc;
      }
    }

    @media only screen and (max-width: 991px) {
      .kf-react-datepicker {
        .input-field__prefix {
          display: flex;
        }
      }
    }
  }
}